<template>
    <a-card>
        <a-page-header title='橙子建站落地页报表' />
        <a-form-model :model="form" v-bind='layoutTop'>
            <a-row type="flex" justify="start">
                <a-col :span="12">
                    <a-form-model-item label="查询周期">
                        <a-range-picker v-model="pickerTime" value-format="YYYY-MM-DD" :disabled-date="disabledDate"
                            :allow-clear="false" @calendarChange="calendarChange" @openChange="openChange" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="统计方式">
                        <a-radio-group v-model="form.statisticType">
                            <a-radio v-for="value in methodsTypeList" :key="value.value" :value="value.value">
                                {{ value.text }}
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <a-form-model :model="form" v-bind='layoutBottom'>
            <a-row type="flex" justify="start">
                <a-col :span="6">
                    <a-form-model-item label="建站名称">
                        <a-input v-model.trim="form.adLandingPageName" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="站点ID">
                        <a-input v-model.trim="form.adLandingPageId" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="广告主">
                        <a-input v-model.trim="form.advertiserName" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="平台账套ID">
                        <a-input v-model.trim="form.adPlatformAccountId" placeholder="请输入" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-between">
                <a-col>
                    <a-button style="margin-left: 25px;" :loading="exportBtnLoading" @click="handleExport">导出数据</a-button>
                </a-col>
                <a-col>
                    <a-button @click="handleReset">重置</a-button>
                    <a-button style="margin: 0 18px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
                </a-col>
            </a-row>

            <a-table style="margin-top: 20px;" size="small" :loading="tableLoading" :columns="columns"
                :data-source="dataList" :rowKey="(record, index) => index" :pagination='false' :scroll="{ x: 2600 }"
                @change="handleTableChange">
                <div slot="calculateCycle" slot-scope="text, record">
                    {{ record.timeString }}
                </div>
                <div slot="materialSlot">
                    <div>建站名称</div>
                    <div>站点ID</div>
                </div>
                <div slot="adLandingPageId" slot-scope="text, record">
                    <div class="blue-text" @click="handleOpenPage(record.previewUrl)"> {{ record.adLandingPageName }}</div>
                    <div class="gray-text">ID: {{ record.adLandingPageId }}</div>
                </div>
                <div slot="reviewUrl" slot-scope="text, record" class="img-container">
                    <img style="cursor: pointer;" :src="record.thumbnail" alt="视频封面" @click="handleOpenPage(record.previewUrl)">
                </div>
                <div slot="advUserSlot">
                    <div>广告主</div>
                    <div>平台套账ID</div>
                </div>
                <div slot="advertiserName" slot-scope="text, record">
                    <div> {{ record.advertiserName }}</div>
                    <div class="gray-text">ID: {{ record.adPlatformAccountId }}</div>
                </div>
            </a-table>
            <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
                @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />

        </a-form-model>
    </a-card>
</template>

<script>
import moment from 'moment'
import { methodsTypeList } from '../JLAdvMaterialManage/typeList'
import { downloadExcel } from "@/utils";

export default {
    data() {
        const columns = [
            {
                align: 'center',
                fixed: 'left',
                title: '统计周期',
                width: 100,
                key: 'calculateCycle',
                scopedSlots: { customRender: 'calculateCycle' },
            },
            {
                fixed: 'left',
                width: 150,
                align: 'center',
                key: 'adLandingPageId',
                slots: { title: 'materialSlot' },
                scopedSlots: { customRender: 'adLandingPageId' },
            },
            {
                fixed: 'left',
                title: '预览图',
                align: 'center',
                width: 150,
                scopedSlots: { customRender: 'reviewUrl' },
            },
            {
                fixed: 'left',
                width: 150,
                align: 'center',
                key: 'advertiserName',
                slots: { title: 'advUserSlot' },
                scopedSlots: { customRender: 'advertiserName' },
            },
            {
                align: 'center',
                sorter: true,
                title: '消费(元)',
                key: 'statCost',
                customRender(text) {
                    return text.metric.statCost
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '目标转化数',
                key: 'convertCnt',
                customRender(text) {
                    return text.metric.convertCnt
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '转化单价(元)',
                key: 'convertCost',
                customRender(text) {
                    return text.metric.convertCost
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '访问量',
                key: 'pv',
                customRender(text) {
                    return text.metric.pv
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '访问转化率(%)',
                key: 'viewConvertRate',
                customRender(text) {
                    return text.metric.viewConvertRate
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '3秒停留数',
                key: 'ge3sStayCount',
                customRender(text) {
                    return text.metric.ge3sStayCount
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '3秒停留率(%)',
                key: 'ge3sStayRate',
                customRender(text) {
                    return text.metric.ge3sStayRate
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '停留转化率(%)',
                key: 'ge3sStayConvertRate',
                customRender(text) {
                    return text.metric.ge3sStayConvertRate
                }
            },
            {
                align: 'center',
                sorter: true,
                title: '昨日平均加载时长',
                key: 'yesterdayAvgLoadDuration',
                customRender(text) {
                    return text.metric.yesterdayAvgLoadDuration == 0 ? 0 : text.metric.yesterdayAvgLoadDuration ? text.metric.yesterdayAvgLoadDuration : '-'
                }
            }
        ]
        return {
            methodsTypeList,
            layoutTop: {
                labelCol: { span: 4 },
                wrapperCol: { span: 16 }
            },
            layoutBottom: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                statisticType: 'TOTAL',
            },
            // 默认查询30天前
            pickerTime: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().subtract(1, "days").format("YYYY-MM-DD")],
            selectStartDay: '',
            tableLoading: false,
            columns,
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            dataList: [],
            sortList: [],
            exportBtnLoading: false
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        async getDataList(isExportCheck = false) {
            // isExportCheck 导出查询
            const startTime = `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`
            const endTime = `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`
            const params = {
                ...this.form,
                startTime,
                endTime,
                sortList: this.sortList,
                page: isExportCheck ? 1 : this.pagination.current,
                size: this.pagination.pageSize,
            }
            const returnData = {
                params,
                total: 0
            }
            const callbackFn = (data) => {
                if (!isExportCheck) {
                    this.dataList = data.list.map((value) => {
                        // 时间范围显示
                        if (value.statStartTime) {
                            switch (this.form.statisticType) {
                                case "TOTAL":
                                    value.timeString = `${value.statStartTime} ~ ${value.statEndTime}`
                                    break;
                                case "GROUP_BY_DAY":
                                    value.timeString = `${value.statStartTime}`
                                    break;
                                case "GROUP_BY_MONTH":
                                    value.timeString = `${moment(value.statStartTime).format('YYYY-MM')}`
                                    break;
                            }
                        } else {
                            value.timeString = '-'
                        }
                        return value
                    })
                    this.pagination.total = data.total || 0
                } else {
                    // 返回导出检查条数
                    returnData.total = data.total || 0
                }
            }
            if (!isExportCheck) this.tableLoading = true
            await this.handleRequest('getLandingList', (data) => {
                callbackFn(data)
            }, params)
            this.tableLoading = false
            return returnData
        },
        handleSearch() {
            const startTime = moment(this.pickerTime[0])
            const endTime = moment(this.pickerTime[1])
            const betweenDays = endTime.diff(startTime, 'day')
            if (this.form.statisticType === 'GROUP_BY_DAY' && betweenDays > 31) return this.$message.info('按日统计的查询周期不能＞31天，请调整查询周期后再设置')
            this.pagination.current = 1
            this.getDataList()
        },
        calendarChange(dates, dateStrings) {
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        disabledDate(current) {
            if (this.form.statisticType === 'GROUP_BY_DAY') {
                return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days')
            } else {
                return current > this.$moment(this.selectStartDay).add(366, 'days') || current < this.$moment(this.selectStartDay).subtract(366, 'days')
            }
        },
        handleTableChange(pagination, filters, sorter) {
            const sort = sorter.order ? [{
                fieldName: sorter.columnKey,
                orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            }] : []
            this.sortList = sort
            this.pagination.current = 1
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        handleReset() {
            this.form = this.$options.data().form
            this.pickerTime = this.$options.data().pickerTime
            this.selectStartDay = ''
        },
        async handleExport() {
            const startTime = moment(this.pickerTime[0])
            const endTime = moment(this.pickerTime[1])
            const betweenDays = endTime.diff(startTime, 'day')
            if (this.form.statisticType === 'GROUP_BY_DAY' && betweenDays > 31) return this.$message.info('按日统计的查询周期不能＞31天，请调整查询周期后再设置')
            this.exportBtnLoading = true
            // 先检查当前查询条件条数
            const { params, total } = await this.getDataList(true)
            if (!total) {
                // 当前查询条件无结果 不处理导出
                this.$message.error('无内容，请重新设置查询条件')
            } else {
                const { blob, fileName } = await this.$api.core.ReleaseEffectReport.exportLadingPageList(params)
                downloadExcel(blob, decodeURIComponent(fileName));
                this.$message.success('操作成功')
            }
            this.exportBtnLoading = false
        },
        handleOpenPage(url) {
            // TODO
            if(url){
                window.open(url, '_blank')
            }else{
                this.$message.info('暂无数据')
            }
            // this.handleRequest('getMaterialUrl', (data) => {
            // }, id)
        },
        async handleRequest(API, callback, params = null) {
            const { code, message, data } = await this.$api.core.ReleaseEffectReport[API](params)
            if (code === 200) {
                callback(data)
            } else {
                this.$message.error(`${message}`)
            }
        },
    }
}
</script>

<style scoped lang="less">
.img-container {
    width: 100%;
    text-align: center;

    img {
        // width: 100%;
        object-fit: cover;
        height: 75px;
    }
}

.gray-text {
    color: #bbbbbb
}

.blue-text {
    color: #599af8;
    cursor: pointer;
    text-decoration: underline;
}
</style>