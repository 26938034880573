export const materiaList = [
    {
        value: 'VIDEO',
        text: '视频'
    },
    {
        value: 'IMAGE',
        text: '图片'
    },
    {
        value: 'TITLE',
        text: '标题'
    },
    {
        value: 'OTHER',
        text: '其他'
    },
]

export const methodsTypeList = [
    {
        value: 'TOTAL',
        text: '合计'
    },
    {
        value: 'GROUP_BY_DAY',
        text: '按日'
    },
    {
        value: 'GROUP_BY_MONTH',
        text: '按月'
    }
]